@import "../../mixins.scss";

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

.MenuItem {
  cursor: pointer;
  @include respond(sm) {
    opacity: 0;
    animation: 1s appear forwards;
    animation-delay: 0.1s;
  }

  .Item {
    @include respond(sm) {
      padding: 1rem 0;
      margin: 0 5%;
      transition: color 0.2s ease-in-out;
      animation: 0.5s slideIn forwards;
      animation-delay: 0.2s;
    }
  }
 
  .Nav-Link {
    color: white;
    &:hover {
      color: var(--color-yellow);
    }
    &.Active {
      color: var(--color-yellow);
    }
  }

  .Line {
    @include respond(sm) {
      width: 90%;
      height: 1px;
      background: #fafafa;
      margin: 0 auto;
      animation: 0.5s shrink forwards;
      animation-delay: 0.1s
    }
  }
}
