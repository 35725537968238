.MobileMenu {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: var(--color-primary-dark);;
  opacity: 0.95;
  color: #fafafa;
  transition: height 0.3s ease;
  z-index: 2;
};
