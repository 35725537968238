.Footer {
  display: grid;
  grid-template-rows: 80% auto;
  background: var(--color-primary-dark);
}

.Footer-Section1 {
  padding: 1rem;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #9945FF;
}

.Footer-Content > div {
  padding-bottom: 1rem;
}

.Description {
  width: 15rem;
}

.Footer-Section1-Title {
  color: #90c790;
}

.Contact {
  color: #9945FF;
}

.Footer-Section2 {
  padding: 4px;
  display: flex;
  align-items: center;
}

.Footnote {
  margin-left: 5rem;
}

.Mobile-Menu {
  display: flex;
  padding: 1rem;
  align-items: center;

  & > * {
    padding-right: 1rem;
  }
}
