.Collapse {
  display: flex;
  padding: 20px 50px;
  border-bottom: 2px solid var(--color-primary-light);
  justify-content: space-between;
}

// .CollapseContent {
//   background: #3B4B3C;
// }

.Toggle {
  color: #F803FE;
  cursor: pointer;
}

.ReactCollapse--collapse {
  transition: height 500ms;
  background: var(--color-primary-light-2);
}
