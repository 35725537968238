@import "../../mixins.scss";

.About {
  display: flex;
  justify-content: center;
  padding: 2rem;

  .Image {
    @include respond(sm) {
      max-width: 100%;
      width: 100%;
      max-height: 100%;
    }
    width: 60%;
    max-height: 90%;
    max-width: 100%;
  }
}
