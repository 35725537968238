.RoadMap {
  .Content {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    padding-top: 2rem;

    .RoadMapItem {
      background: var(--color-primary-dark-2);;
      border-radius: 4px;
      margin-bottom: 1rem;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Title {
        line-height: 2rem;
        color: var(--color-yellow);
        text-transform: uppercase;
      }

      .SubTitle {
        line-height: 2rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
      }

      .Desc {
        font-size: 12px;
        color: #892DFF;
      }
    }
  }
}
