@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
*{box-sizing:border-box}:root{--color-primary-dark: #33004C;--color-primary-dark-2: #48158A;--color-primary-light: #430063;--color-primary-light-2: #6D2AC1;--color-pink: #F803FE;--color-yellow: #FFC107}body{margin:0;font-family:'Press Start 2P', cursive;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-size:14px;background:#430063;background:var(--color-primary-light)}code{font-family:'Press Start 2P', cursive}

.Logo{display:flex;align-items:center;font-size:16px;cursor:pointer}.Logo-Img{margin:0 10px}

@keyframes appear{0%{opacity:0}100%{opacity:1}}@keyframes slideIn{0%{transform:translateX(-2%)}100%{transform:translateX(0)}}@keyframes shrink{0%{width:95%}100%{width:90%}}.MenuItem{cursor:pointer}@media only screen and (max-width: 640px){.MenuItem{opacity:0;animation:1s appear forwards;animation-delay:0.1s}}@media only screen and (max-width: 640px){.MenuItem .Item{padding:1rem 0;margin:0 5%;transition:color 0.2s ease-in-out;animation:0.5s slideIn forwards;animation-delay:0.2s}}.MenuItem .Nav-Link{color:white}.MenuItem .Nav-Link:hover{color:var(--color-yellow)}.MenuItem .Nav-Link.Active{color:var(--color-yellow)}@media only screen and (max-width: 640px){.MenuItem .Line{width:90%;height:1px;background:#fafafa;margin:0 auto;animation:0.5s shrink forwards;animation-delay:0.1s}}

.MenuButton{height:32px;width:32px;display:flex;flex-direction:column;justify-content:center;align-items:center;cursor:pointer;padding:4px;margin-left:auto;background:var(--color-primary-dark-2);margin-right:10px}.MenuButton .Line{height:2px;width:20px;background:var(--color-pink);transition:all 0.2s ease}

.MobileMenu{position:absolute;top:55px;left:0;width:100vw;display:flex;flex-direction:column;background:var(--color-primary-dark);opacity:0.95;color:#fafafa;transition:height 0.3s ease;z-index:2}

.Menu{display:flex;justify-content:space-evenly;margin-left:7rem;font-size:14px}.Menu-Item{cursor:pointer}.Menu-Item .Nav-Link{color:white}.Menu-Item .Nav-Link:hover{color:var(--color-yellow)}.Menu-Item .Nav-Link.Active{color:var(--color-yellow)}.App-Bar{display:grid;grid-template-columns:minmax(-webkit-min-content, 20vw) 65vw auto;grid-template-columns:minmax(min-content, 20vw) 65vw auto;background:var(--color-primary-dark);align-items:center}@media only screen and (max-width: 640px){.App-Bar{display:flex}}.Connect-Btn{background:#ffc107 !important;padding:8px !important;border:3px solid #ff9900 !important;text-align:center !important;cursor:pointer !important;color:inherit !important;font-family:inherit !important;justify-content:center !important;font-size:14px !important;line-height:18px !important}

.Collapse{display:flex;padding:20px 50px;border-bottom:2px solid var(--color-primary-light);justify-content:space-between}.Toggle{color:#F803FE;cursor:pointer}.ReactCollapse--collapse{transition:height 500ms;background:var(--color-primary-light-2)}

.Footer{display:grid;grid-template-rows:80% auto;background:var(--color-primary-dark)}.Footer-Section1{padding:1rem;display:flex;justify-content:space-evenly;border-bottom:1px solid #9945FF}.Footer-Content>div{padding-bottom:1rem}.Description{width:15rem}.Footer-Section1-Title{color:#90c790}.Contact{color:#9945FF}.Footer-Section2{padding:4px;display:flex;align-items:center}.Footnote{margin-left:5rem}.Mobile-Menu{display:flex;padding:1rem;align-items:center}.Mobile-Menu>*{padding-right:1rem}

.FAQ{display:grid}.FAQ .Main{background:var(--color-primary-light);display:flex;flex-direction:column;align-items:center;padding-bottom:5rem}.FAQ .Title{font-size:24px;padding:3rem;text-align:center}.FAQ .Questions{width:65%;background:var(--color-primary-dark);font-size:14px}@media only screen and (max-width: 640px){.FAQ .Questions{width:auto;margin:0 1rem}}

.Landing{display:grid;grid-template-columns:60% 40%;background:url(/static/media/background.0ab142c7.png);background-repeat:no-repeat;background-size:cover}@media only screen and (max-width: 640px){.Landing{grid-template-columns:auto}}.Landing .Section1{padding-left:7%;padding-top:13%;padding-right:7%}.Landing .Title{font-size:24px;margin-bottom:9%;color:white}@media only screen and (max-width: 640px){.Landing .Title{padding:3%}}.Landing .Feature{display:flex;align-items:center;color:#FF00F5}.Landing .Feature-Value{margin-left:1rem}.Landing .Section2{padding-top:9%;padding-right:10%;padding-bottom:8%}@media only screen and (max-width: 640px){.Landing .Section2{padding:3%}}.Landing .BlankImage{width:440px;max-height:100%;max-width:100%}.Landing .WalletID{color:var(--color-pink)}

.Rarity{display:grid;background:var(--color-primary-light);display:flex;flex-direction:column;align-items:center}.Rarity .Attributes{margin-top:3rem;width:90%;display:flex;flex-wrap:wrap;justify-content:center}.Rarity .Attribute{text-transform:uppercase;padding:1rem;background-color:var(--color-primary-dark-2);border-radius:4px;cursor:pointer;text-align:center;overflow:hidden;text-overflow:ellipsis;flex:0 0 147px;padding:20px;margin:10px}.Rarity .Attribute.Active{color:var(--color-yellow)}.Rarity .Attribute:hover{color:var(--color-yellow)}.Rarity .Cards{margin:3rem auto 1rem auto;width:80%;display:flex;flex-wrap:wrap;justify-content:center}.Rarity .Card{text-transform:uppercase;padding:0.7rem;padding-bottom:2rem;background-color:var(--color-primary-light-2);border-radius:10px;cursor:pointer;text-align:center;height:15rem;display:flex;flex-direction:column;justify-content:space-between;align-items:center;max-width:11rem;border:2px solid #892dff;margin:10px;flex:0 0 176px}@media only screen and (max-width: 640px){.Rarity .Card{max-width:none;height:24rem;width:100%;flex:0 1 370px}}.Rarity .Card img{width:100px;height:100px}@media only screen and (max-width: 640px){.Rarity .Card img{width:200px;height:200px}}.Rarity .Rare{margin-right:auto;height:14px}.Rarity .RareStar{color:var(--color-yellow)}

.About{display:flex;justify-content:center;padding:2rem}.About .Image{width:60%;max-height:90%;max-width:100%}@media only screen and (max-width: 640px){.About .Image{max-width:100%;width:100%;max-height:100%}}

.RoadMap .Content{margin-left:auto;margin-right:auto;width:70%;padding-top:2rem}.RoadMap .Content .RoadMapItem{background:var(--color-primary-dark-2);border-radius:4px;margin-bottom:1rem;padding:1rem;display:flex;justify-content:space-between;align-items:center}.RoadMap .Content .RoadMapItem .Title{line-height:2rem;color:var(--color-yellow);text-transform:uppercase}.RoadMap .Content .RoadMapItem .SubTitle{line-height:2rem;margin-bottom:1rem;text-transform:uppercase}.RoadMap .Content .RoadMapItem .Desc{font-size:12px;color:#892DFF}

.App {
  color: white;
  display: grid;
  grid-template-rows: 8vh minmax(92vh, -webkit-min-content);
  grid-template-rows: 8vh minmax(92vh, min-content);
}

