.Logo {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.Logo-Img {
  margin: 0 10px;
}
