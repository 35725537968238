@import "../../mixins.scss";

.Menu {
  display: flex;
  justify-content: space-evenly;
  margin-left: 7rem;
  font-size: 14px;
}

.Menu-Item {
  cursor: pointer;
  .Nav-Link {
    color: white;
    &:hover {
      color: var(--color-yellow);
    }
    &.Active {
      color: var(--color-yellow);
    }
  }
}

.App-Bar {
  display: grid;
  grid-template-columns: minmax(min-content, 20vw) 65vw auto;

  @include respond(sm) {
    display: flex;
  }

  background: var(--color-primary-dark);
  align-items: center;
}

.Connect-Btn {
  background: #ffc107 !important;
  padding: 8px !important;
  border: 3px solid #ff9900 !important;
  text-align: center !important;
  cursor: pointer !important;
  color: inherit !important;
  font-family: inherit !important;
  justify-content: center !important;
  font-size: 14px !important;
  line-height: 18px !important;
}
