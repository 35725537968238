.MenuButton {
  height: 32px;
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  margin-left: auto;
  background: var(--color-primary-dark-2);
  margin-right: 10px;
  
  .Line {
    height: 2px;
    width: 20px;
    background: var(--color-pink);
    transition: all 0.2s ease;
  };
};
